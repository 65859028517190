.container{
    max-width: 800px;
    margin: 0 auto;
}
.title{
    max-width: 700px;
    margin: 0 auto;
}
.step_text{
    text-align: left;
    font-weight: bold;
}
.purchase_wrapper{
    max-width: 700px;
    margin: 0 auto;
}
.error_text{
    color: #ff0000;
    min-height: 20px;
    z-index: 1;
}
.wrapper{
    display: flex;
    justify-content: space-between;
    max-width: 700px;
    margin: 0 auto;
}
.title_wrapper{
    max-width: 800px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;
}
.text_field,.text_place{
    margin-bottom: 30px;
    width: 220px;
}

.text_field_small,.text_place_small{
    margin-bottom: 30px;
    width: 100px;
    margin-right: 30px;
}
.text_field_big,.text_place_big{
    margin-bottom: 30px;
    width: 100%;
}
h1{
    color: #727272;
    width: 650px;
    margin: 0;
}
.inputs_wrapper_small{
    display: flex;
}
.input_wrapper,.input_wrapper_code{
    margin-top: 30px;
    position: relative;
    display: flex;
    flex-direction: column;
    margin-right: 31px;
}
.input_wrapper_code{
    width: 220px;
}
.input,.input_large,.input_small,.input_medium{    
        border: 1px solid #EAEEF7;
        border-radius: 5px;
        position: relative;   
        margin: 0;
        padding-left: 20px;
        font-size: 16px;
        line-height: 6ex;
}
.input_wrapper_purchase{
    position: relative;
    margin-top: 20px;
    width: 300px;
}
.input_purchase{
    border: 1px solid #EAEEF7;
    border-radius: 5px;
    position: relative;   
    height: 45px;
    width: 300px;
    margin: 0;
    padding-left: 20px;
    font-size: 16px;
    line-height: 6ex;
}
.input_gender{
    border: 1px solid #EAEEF7;
    border-radius: 5px;
    position: relative;   
    height: 50px;
    margin: 0;
    padding-left: 20px;
    font-size: 16px;
    line-height: 6ex;
}
.input_frame{
    margin-bottom: 20px;
}
.text_field_wrapper{
    margin-bottom: 30px;
    height: 40px;
   
}
.text_field_wrapper_small{
    margin-right: 20px;
    width: 100px;
}
.input{
    width: 220px;
    height: 45px;
}
.input_large{
    word-break: break-all;
    display: flex;
    height: 150px;
    max-width: 480px;
    line-height:20px;
    padding: 10px;
}
/* SELECT */
.custom_select_wrapper{
    border: 1px solid #EAEEF7;
    background-color: #fff;
    border-radius: 10px;
    width: 100%;
    position: absolute;
    top: 63%;
    z-index: 10;
    box-shadow:  1px 4px 7px 5px #0000000a;
    display: block;
}
.custom_select_wrapper_disabled{
    display: none;
}
.select_list{
    padding: 2px;
    margin: 0;
}
.list_item{
    text-decoration: none;
    list-style: none;
    margin: 2px;
    padding: 3px 3px 3px 10px;
    cursor: pointer;
}
.list_item:hover{
    background-color: #eaeef782;
}
/* **SELECT** */
.input_medium{
    max-width: 480px;
    height: 50px;
}
.input_small{
    width: 100px;
    height: 45px;
}
label {
    position: absolute;
    top: -1ex;
    z-index: 1;
    left: .5em;
    background-color: white;
    padding: 0 5px;
    color: #727272;
  }
  .button_wrapper{
    display: flex;
    justify-content: center;
    margin-top: 40px;
    margin-bottom: 40px;
}
.button{
    max-width: 470px;
    min-width: 300px;
    height: 50px;
    background-color: #69CD7C;
    border: none;
    color: #fff;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
}
@media screen and (max-width:425px){
    .step_text{
        max-width: 300px;
        margin: 0 auto;
        margin-bottom: 30px;
    }
}
@media screen and (max-width:768px) and (min-width:425px){
    .step_text{
        max-width: 400px;
        margin: 0 auto;
        margin-bottom: 30px;
    }
    .title{
        margin: 0 auto;
        justify-content: center;
    }
}
@media screen and (max-width:768px){
    .container{
        padding: 0 10px;
        margin-top: 20px;
    }
    .inputs_wrapper_small{
        display: flex;
        flex-direction: column;
        margin: 0 auto;
    }
    .title{
        width: inherit;
    }
    .input_wrapper,.input_wrapper_code,.input_wrapper_purchase{
        width: 300px; 
        margin: 0 auto;
        margin-bottom: 20px;    
    }
    .input_purchase{
        width: 300px;
    }
    .input{
        width: 300px;
    }
    .wrapper{
        display: flex;
        flex-direction: column;
    }
    .text_field_small{
        width: 100%;
        
    }
    .text_field_wrapper_small{
        height: auto;
    }
    .text_field{
        width: 100%;
        margin-bottom: 20px;
    }
    .text_field_wrapper{
        margin: 0;
        margin-bottom: 20px;
    }
    .input,.input_small,.input_large{
        margin: 0 auto;
    }
    .input_large{
        padding: 5px ;
    }
    .input_large,.input_small,.input{
        
        width: 300px;
    }
    .button_wrapper{
        margin-top: 20px;
        margin-bottom: 40px;
    }
    .input_medium{
        height: 100px;
    }
}