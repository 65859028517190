.container {
    max-width: 800px;
    margin: 0 auto;
    padding: 0 30px 30px 30px;
}

.logo {
    height: 100px;
}

.header {
    display: flex;
    gap: 30px;
    justify-content: space-around;
    padding-bottom: 30px;
    align-items: center;
}

.header span {
    color: #727272;
    font-size: 36px;
    text-transform: uppercase;
    font-weight: 600;
}

.next_steps_text {
    font-size: 1.5em;
}

li {
    font-size: 1.1em;
}

@media screen and(max-width:483px) {
    .header {
        flex-direction: column;
    }
}