*,
*::before,
*::after {
  box-sizing: border-box;
}
.container{
    max-width: 800px;
    margin: 0 auto;
    
}
/* SELECT */
.custom_select_wrapper{
    border: 1px solid #EAEEF7;
    background-color: #fff;
    border-radius: 10px;
    width: 100%;
    position: absolute;
    top: 63%;
    z-index: 10;
    box-shadow:  1px 4px 7px 5px #0000000a;
    display: block;
}
.custom_select_wrapper_disabled{
    display: none;
}
.select_list{
    padding: 2px;
    margin: 0;
}
.list_item{
    text-decoration: none;
    list-style: none;
    margin: 2px;
    padding: 3px 3px 3px 10px;
    cursor: pointer;
}
.list_item:hover{
    background-color: #eaeef782;
}
/* **SELECT** */
.input_wrapper{
    position: relative;
    min-width: 220px;
}
.wrapper{
    display: flex;
    justify-content: space-between;
    max-width: 480px;
}
.input:focus{
    border:2px solid #69CD7C
}
.input{
    min-width: 220px;
    height: 50px;
    padding: 1px 5px 1px 5px;
    font-size: 16px;
    margin-bottom: 30px;
    border-radius: 5px;
    border: 2px solid #EAEEF7;
    outline: none;
    transition:  .1s linear border;
    -webkit-animation: .1s linear border;
}
.error_text{
    color: red;
    margin: 0 auto;
    position: absolute;
    top: 60%;
    left: 13%;
}
.label{
    font-size: 14px;
}
.container{
    max-width: 800px;
    margin: 0 auto;
}

.title_wrapper{
    max-width: 800px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;
}
h1{
    color: #727272;
    width: 650px;
    margin: 0;
}

.switch_container{
    display: flex;
    margin-top: 35px;
    align-items: center;
}
.switch_label{
    margin-left: 25px;
    font-size: 16px;
    color: #727272;
}
.switcher_frame{
    border: 1px solid #727272;
    height: 31px;
    border-radius: 60px;
    width: 51px;
    display: flex;
    align-items: center;
    justify-content: center;
}
/* input */

.text_place{
    margin: 30px 30px 0 0;
    height: 50px;
    width: 220px;
}
.text_field{
    width: 220px;
}
label {
    position: absolute;
    top: -1ex;
    z-index: 1;
    left: .5em;
    background-color: white;
    padding: 0 5px;
    color: #727272;
  }
/* button */
.button_wrapper{
    display: flex;
    justify-content: center;
    margin-top: 113px;
    margin-bottom: 113px;
}
.button{
    max-width: 470px;
    min-width: 300px;
    height: 50px;
    background-color: #69CD7C;
    border: none;
    color: #fff;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;   
}
.Link{
    text-decoration: none;
}
/* Adaptive */
@media screen and (max-width:320px) {
    .title_wrapper{
        display: none;
        margin: 0 auto;
    }
    .error_text{
        left: 23%;
    }
    .container{
        margin-top: 40px;
        padding: 0 30px;
    }
    .text_place{
        margin: 0 auto;
        margin-bottom: 20px;
        margin-top: 20px;
    }
    .button_wrapper{
        margin: 30px 0;
    }
    .wrapper{
        flex-direction: column;
    }
    .input{
        height: 50px;
    }
    
}
@media screen and (min-width:321px) and (max-width:487px){
    .title_wrapper{
        display: none;
        margin: 0 auto;
    }
    .wrapper{
        flex-direction: column;
    }
    .input{
        height: 50px;
    }
    .input_plate{
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        align-items: center;
        justify-content: center;
    }
    
    
    .container{
        margin-top: 40px;
        padding: 0 30px;
    }
    .text_place{
        margin: 0 auto;
        margin-bottom: 20px;
        margin-top: 20px;
    }
    .button_wrapper{
        margin: 30px 0;
    }
}
@media screen and (max-width:1024px) and (min-width:488px){
   
    .container{
        padding: 0 30px;
    }
    .title{
        margin-left: 10px;
        height: inherit;
    }
}
@media screen and (max-width:486px) {
    .input_wrapper{
        width: 290px;
    }
    .input{
        width: 290px;
    }
}
