.container{
    max-width: 800px;
    margin: 0 auto;
    margin-top: 20px;
}
.title_img_info{
    max-width: 170px;
}
.track_num_wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.text_main{
    text-align: center;
    color: #727272;
    font-size: 18px;
    max-width: 600px;
    margin: 0 auto;
    margin-bottom: 20px;
}
.input{
    min-width: 220px;
    width: 100%;
    height: 50px;
    padding: 1px 5px 1px 5px;
    font-size: 16px;
    margin-bottom: 30px;
    border-radius: 5px;
    border: 1px solid #EAEEF7;
    outline: none;
}
.input_wrapper{
    position: relative;
    min-width: 220px;
    max-width: 400px;
    margin: 0 auto;
    
}
.label{
    font-size: 14px;
}
.error_text{
    color: red;
    margin: 0 auto;
    position: absolute;
    top: 60%;
    left: 30%;
}
.text_title{
    color: #727272;
    font-size: 18px;
    text-align: left;
    margin-left: 20px;
}
.text_submain{
    text-align: center;
    color: #727272;
    font-size: 18px;
    max-width: 500px;
    margin: 0 auto;
    margin-bottom: 20px;
}
.track_num_text{
    font-size: 20px;
    color: #fff;
    max-width: 600px;
}
.link{
    color: #69CD7C;
    cursor: pointer;
    text-decoration: none;
}
.link_wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    margin-top: 40px;
}
.link:hover{
    cursor: pointer;
}
.track_num{
    background-color: #69CD7C;
    width: 300px;
    margin: 0 auto;
    border-radius: 5px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
}
.text_green{
    color: #69CD7C;
    cursor: pointer;
}
.data_text{
    font-weight: bold;
}
/* title */
.tracknum_title{
    margin-left: 20px;
    text-transform: uppercase;
    color: #727272;
}
.title{
    height: inherit;
    margin-left: 20px;
    text-transform: uppercase;
}
.title_info{
    height: inherit;
    margin-left: 20px;
    text-transform: uppercase;
}
.title_wrapper{
    max-width: 700px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
}
.title_wrapper_info{
    max-width: 700px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
}
/* ** */
.button_container{
    max-width: 700px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 30px;
}
.buttons_container{
    max-width: 700px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 0 auto;
    margin-top: 10px;
    margin-bottom: 30px;
}
.button{
    max-width: 470px;
    min-width: 300px;
    height: 50px;
    background-color: #69CD7C;
    border: none;
    color: #fff;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    text-decoration: none;
}
/* *** */
/* Adaptivity */
@media screen and (max-width:320px){
    .error_text{
        left: 25%;
    }
} 
@media screen and (max-width:425px) {
    .container{
        padding: 10px 10px;
    }
    .text_title{
        width: 200px;
        font-size: 16px;
        margin: 0;
    }
    .title_img_info{
        max-width: 130px;
    }
    .title{
        width: 200px;
        margin-left: 0;
        margin-bottom: 10px;
    }
    .title_info{
        width: 200px;
        margin-left: 0;
        margin-bottom: 10px;

    }
    .title_img{
        max-width: 130px;
    }
    .tracknum_title{
        margin-left: 0;
    }
    .qr_text_wrapper{
        display: flex;
        flex-direction: column;
    }
    .buttons_container{
        flex-direction: column;
    }
    .button{
        margin-bottom: 20px;
    }
    .title_img_info{
        max-width: 100px;
    }
 
    .title_wrapper_info{
        flex-direction: column;
    }
}
@media screen and (min-width:425px) and (max-width:645px){
    .container{
        padding: 10px 10px;
    }
    .buttons_container{
        flex-direction: column;
    }
    .button{
        margin-bottom: 20px;
    }
    .tracknum_title{
        margin-left: 0;
    }
}
@media screen and (min-width:579px) and (max-width:770px){
    .title{
        text-align: center;
        margin-left: 15px;
    }
    .title_wrapper{
        max-width: 579px;
        margin: 0 auto;
    }
}