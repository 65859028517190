.container{
    width: 200px;
    background: #ffffff;
            height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    border: 1px solid #C5C5C5;
    /* Shadow / 2 */
    
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
    align-items: center;
}
.container a{
    color: #adadad;
    font-size: 18px;
    text-decoration: none;
}
.active{
    color: #50c01c!important;
}
.button{
    text-decoration: none;
    color: #fff;
    background-color: #3c8d16;
    padding: 8px 16px;
    font-size: 16px;
    border-radius: 4px;
    transition: .1s linear all;
    display: flex;
    gap: 4px;
    justify-content: center;
    align-items: center;
}