.box{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 300px;
    height: 225px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap:10px;
    flex-direction: column;
}