.container{
    padding: 10px;
    max-width: 800px;
    margin: 0 auto;
}
.wrapper{
    padding: 10px;
}
table{
    width: 1150px;
    margin: 0 auto;
    margin-top: 30px;
}
thead{
    color: #C1D3DC;
}
th{
    width: 200px;
}
.buttons{
    display: flex;
}
.table_wrapper{
    margin-top: 30px;
}
.btn{
    width: 100%;
    height: 40px;
    background-color: #69CD7C;
    color: #fff;
    border: none;
    border-radius: 5px;
    margin: 0 5px;
}
/* title */
.title_wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 550px;
    margin: 0 auto;
}
.my_title{
    height: inherit;
    width: 400px;
}
.title{
    max-width: 600px;
    margin: 0 auto;
    text-transform: uppercase;
    text-align: center;
}
/* Signs */
.signs{
    display: flex;
    justify-content: space-between;
    max-width: 720px;
    margin: 0 auto;
}
.sign{
    width: 220px;
    height: 270px;
    border: 1px solid #C1D3DC;
    border-radius: 20px;
    padding: 15px 10px 10px 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.sign_img{
    width: 165px;
   height: 200px;
}
.sign_name{
    margin-top: 20px;
    font-size: 18px;
    color: #727272;
}
@media screen and (max-width:425px){
    .table_wrapper{
        max-width: 300px;
        margin: 0 auto;
        overflow-x: scroll;
    }
}
@media screen and (max-width:1300px) and (min-width:426px){
    .table_wrapper{
        max-width: 100%;
        margin: 0 auto;
        overflow-x: scroll;
        margin: 10px;
    }
    .container{
        padding: 10px;
    }
}
@media screen and (max-width:703px) {
    .title_wrapper{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 250px;
        margin: 0 auto;
    }
    .my_title{
        height: inherit;
        width: 200px;
        margin-left: 10px;
    }
    .signs{
        flex-direction: column;
        align-items: center;
    }
    .sign{
        margin: 20px 0 ;
    }
    .title{
        width: 300px;
        margin: 0 auto;
        text-transform: uppercase;
        text-align: center;
        height: inherit;
    }
}
