.container{
    max-width: 800px;
    margin: 0 auto; 
    margin-top: 100px;
}
.title,.subtitle{
    font-size: 18px;
    color: #727272;
    text-align: center;
}
.subtitle{
    font-size: 15px;
    font-weight: 400;
}
.text_wrapper{
    max-width: 300px;
    margin: 0 auto; 
    margin-top: 50px;
}
.loader{
    position: relative;
}
.loader_container{
    position: relative;
    width: 230px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}

.loader_text{
    position: absolute;
    top: 47%;
    left: 25%;
    text-transform: uppercase;
    color: #727272;
}