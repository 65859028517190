.container{
    max-width: 800px;
    margin: 0 auto;
}
.title_wrapper{
    display: flex;
    align-items: center; 
    justify-content: space-between;
}
.title{
    height: 50px;
    text-align: center;
}
.faq_plate{
    margin-top: 80px;
    margin-bottom: 150px;
}
.faq_item{
    border-top: 1px solid #C1D3DC;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.see_text_btn{
    border-radius: 50%;
    border: 1px solid #C1D3DC;
    width: 28px;
    height: 28px;
    font-size: 30px;
    color: #C1D3DC;
    display: flex;
    align-items: center;
    justify-content: center;
}
.faq_text{
    text-align: left;
    border-bottom: 1px solid #C1D3DC;
    padding-bottom: 10px;
}
.question_name{
    font-weight: bold;
    color: #727272;
}
.button_container{
    max-width: 700px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 30px;
    text-decoration: none;

}
.button{
    max-width: 470px;
    min-width: 300px;
    height: 50px;
    background-color: #69CD7C;
    border: none;
    color: #fff;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    text-decoration: none;
}
