.container{
    max-width: 800px;
    margin: 0 auto;
}
.yourcenter_content_wrapper{
    max-width: 600px;
    margin: 0 auto;
    margin-top: 40px;
}
.textarea_wrapper{
    max-width: 300px;
    margin: 20px auto;
    position: relative;
    
    
}
.textarea{
    max-width: 300px;
    width: 100%;
    height: 50px;
    position: relative;
    padding: 15px;
    font-size: 16px;
}
label {
    position: absolute;
    top: -1ex;
    z-index: 1;
    left: .5em;
    background-color: white;
    padding: 0 5px;
    color: #727272;
  }
/* title */
.title{
    height: inherit;
    margin-left: 20px;
    text-transform: uppercase;
}
.title_wrapper{
    max-width: 700px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
/* ** */
/* text */

.qr_text_wrapper{
    display: flex;
    align-items: center;
}
.yourcenter_text{
    text-align: left;
    line-height: 20px;
    font-size: 20px;
    color: #727272;
    padding: 5px;
}
.support_text{
    margin-top: 40px;
    text-align: left;
    line-height: 20px;
    font-size: 16px;
    color: #727272;
}
.active_text{
    margin: 0 4px 0 4px;
    color: #69CD7C;
    cursor: pointer;
    text-decoration: none;
}
/* ** */
/* buttons */
.button_container{
    max-width: 700px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 30px;
}
.button_back{
    width: 300px;
    height: 50px;
    background-color: #69CD7C;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    text-decoration: none;
}
.buttons_container{
    max-width: 700px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 0 auto;
    margin-top: 50px;
    margin-bottom: 30px;
}
.button{
    max-width: 470px;
    min-width: 300px;
    height: 50px;
    background-color: #69CD7C;
    border: none;
    color: #fff;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    text-decoration: none;
}
.button_wrapper{
    margin: 0 auto;
    width: 300px;
}
.link{
    border-radius: 10px;
    background-color: #69CD7C;
    width: 300px;
    height: 50px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: #fff;
    margin-top: 30px;
}
.title_img{
    max-width: 100px;
}
/* *** */
/* Adaptivity */
@media screen and (max-width:425px) {
    .container{
        padding: 10px 10px;
    }
    .qr_text_wrapper{
        display: flex;
        flex-direction: column;
    }
    .buttons_container{
        flex-direction: column;
    }
    .button{
        margin-bottom: 20px;
    }
    .title_img{
        max-width: 80px;
    }
}
@media screen and (min-width:425px) and (max-width:645px){
    .container{
        padding: 10px 10px;
    }
    .buttons_container{
        flex-direction: column;
    }
    .button{
        margin-bottom: 20px;
    }
}
@media screen and (min-width:579px) and (max-width:770px){
    .title{
        text-align: center;
        margin-left: 15px;
    }
    .title_wrapper{
        max-width: 579px;
        margin: 0 auto;
    }
}