

.button{
    text-decoration: none;
    color: #fff;
    background-color: #3c8d16;
    padding: 8px 16px;
    font-size: 16px;
    border-radius: 4px;
    transition: .1s linear all;
    display: flex;
    gap: 4px;
    justify-content: center;
    align-items: center;
}
.button:hover{
    background-color: #50c01c;
}
.button img{
    width: 20px;
    height: 20px;
}
