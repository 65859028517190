.container{
    max-width: 800px;
    margin: 0 auto;
    margin-top: 100px;
}
.courier_content_wrapper{
    max-width: 600px;
    margin: 0 auto;
    margin-top: 50px;
}
/* title */
.title{
    height: inherit;
}
.title_wrapper{
    max-width: 800px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
/* ** */
/* courier text */
.courier_text{
    text-align: left;
    line-height: 20px;
    font-size: 18px;
    color: #727272;
    padding: 5px;
}
/* *** */
/* inputs */
.inputs_container{
    max-width: 600px;
    margin: 0 auto;
}
.date_inputs_wrapper{
    display: flex;
    justify-content: space-between;
}
.input_wrapper,.input_wrapper_phone{
    margin-top: 23px;
    position: relative;
    margin-right: 31px;
}
.input_wrapper_phone{
    width: 270px;
}
/* SELECT */
.custom_select_wrapper{
    border: 1px solid #EAEEF7;
    background-color: #fff;
    border-radius: 10px;
    width: 100%;
    position: absolute;
    top: 100%;
    z-index: 10;
    box-shadow:  1px 4px 7px 5px #0000000a;
    display: block;
}
.custom_select_wrapper_disabled{
    display: none;
}
.select_list{
    padding: 2px;
    margin: 0;
}
.list_item{
    text-decoration: none;
    list-style: none;
    margin: 2px;
    padding: 3px 3px 3px 10px;
    cursor: pointer;
}
.list_item:hover{
    background-color: #eaeef782;
}
.input:focus,.input_phone:focus{
    border:2px solid #69CD7C

}
/* **SELECT** */
.input,.input_phone{
    outline: none;
    transition:  .1s linear border;
    -webkit-animation: .1s linear border;   
    width: 100%;
    margin: 0 auto;
    border: 2px solid #EAEEF7;
    border-radius: 5px;
    position: relative;
    height: 45px;
    margin: 0;
    padding-left: 20px;
    font-size: 16px;
    line-height: 6ex;
}
.text_area:focus{
    border:2px solid #69CD7C

}
.text_area{
    word-break: break-all;
    display: flex;
    height: 150px;
    max-width: 480px;
    line-height:20px;
    padding: 10px;
    width: 100%;
    border: 2px solid #EAEEF7;
    resize: none;
    outline: none;
    border-radius: 5px;
}
.input_phone{
    width: 270px;
}
.input_interval{
    cursor: pointer;
    width: 270px;
    height: 45px;
    padding: 15px 5px 5px 20px;
    font-size: 16px;
    border-radius: 5px;
    border: 2px solid #EAEEF7;
    outline: none;
    position: relative;
}
label {
    position: absolute;
    top: -1ex;
    z-index: 1;
    left: .5em;
    background-color: white;
    padding: 0 5px;
    color: #727272;
  }
  .error_text,.error_text_long{
    color: red;
    margin: 0 auto;
    position: absolute;
    top: 100%;
    left: 20%;
}
.error_text_long{
    left: 40%;
}
/* ** */
/* SELECT */
.select_arrow_disabled{
    position: absolute;
    top: 35%;
    right: 5%;
    
    transform: rotate(180deg);
    transition: .1s ease-in;
}
.select_arrow_active{
    position: absolute;
    top: 35%;
    right: 5%;
    transform: rotate(0deg);
    transition: .1s ease-in;
}
.custom_select_wrapper{
    border: 1px solid #EAEEF7;
    background-color: #fff;
    border-radius: 10px;
    width: 100%;
    position: absolute;
    top: 100%;
    z-index: 10;
    box-shadow:  1px 4px 7px 5px #0000000a;
    display: block;
}
.custom_select_wrapper_disabled{
    display: none;
}
.select_list{
    padding: 2px;
    margin: 0;
    width: 100%;
}
.list_item{
    text-decoration: none;
    list-style: none;
    margin: 2px;
    padding: 10px 3px 10px 10px;
    cursor: pointer;
}
.list_item:hover{
    background-color: #eaeef782;
}
/* **SELECT** */
/* button */
.button_container{
    max-width: 700px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 30px;
}
.button{
    max-width: 470px;
    min-width: 300px;
    height: 50px;
    background-color: #69CD7C;
    border: none;
    color: #fff;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    text-decoration: none;
}

/* *** */
.input_large{
    word-break: break-all;
    display: flex;
    height: 150px;
    max-width: 480px;
    line-height:20px;
    padding: 10px;
}
/* ADAPTIVITY */
@media screen and (max-width:620px){
    .title_img{
        max-width: 80px;
    }
}
@media screen and (max-width:620px) {
    .container{
        padding: 0 10px;
        margin-top: 40px;
    }
    .input,.input_phone,.input_interval,.input_wrapper,.input_wrapper_phone{
        width: 300px;
        margin: 0 auto;
    }
    .input_wrapper{
        margin-top: 0;
    }
    .input_wrapper,.input_wrapper_phone{
        margin-bottom: 30px;
        width: 300px;
    }
    .date_inputs_wrapper{
        flex-direction: column;
    }
    .error_text_long,.error_text{
        left: 25%;
    }
    .inputs_container{
        margin-top: 40px;
    }
    .button_container{
        margin-top: 0px;
    }
}
@media screen and (min-width:528px) and (max-width:834px){
    .title{
        margin-left: 10px;
        width: inherit;
    }
    .title_wrapper{
        justify-content: center;
    }
}
/* ** */