.modal{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 9999;
    display: flex;
    padding: 10px;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(2px);
    background-color: rgba(0, 0, 0, 0.138);
    animation-name: appear;
    animation-duration: 300ms;
}
.modal_dialog{
    /* border-radius: 22px; */
    width: 100%;
    padding: 5px 10px 10px 10px ;
    max-width: 500px;
    background: #ffffff;
    border: 2px solid #C1D3DC;
    border-radius: 10px;
    position: relative;
    margin: 0 auto;
    max-height: calc(100vh - 40px);
    overflow: hidden;
    /* box-shadow: 4px 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.365);
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: slide-in;
    animation-duration: 0.5s;
    max-height: 200px;
}
.modal_text{
    font-weight: 700;
    font-size: 1.3rem;
    margin: 0;
    cursor: pointer;
}
.exit{
    margin: 0;
    display: flex;
    justify-content: flex-end;
}
.close_char{
  color: #C1D3DC;
  font-size: 25px;
}
.modal_disbled{
  opacity: 0;
  animation-name: slide-in;
  animation-duration: 300ms;
  display: none; 
}
.modalBtn_wrapper{
  max-width: 220px;
  margin: 0 auto;
}
.modal_button{
  height: 50px;
  width: 220px;
  background-color: #69CD7C;
  cursor: pointer;
  border: none;
  border-radius: 10px;
  font-size: 18px;
  color: #fff;
}
.text{
  margin-top: 20px;
  margin-bottom: 20px;
}
  @keyframes appear {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes slide-in {
    from {
      transform: translateY(-150px);
    }
    to {
      transform: translateY(0);
    }
  }