.content{
    max-width: 600px;
    margin: 0 auto;
    margin-top: 20px;
}
.link_wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
}
.list{
    color: #727272;
    margin-top: 40px;
}
.list img{
    border-radius: 24px;
    width: 35%;
}
.link{
    color: #69CD7C;
    text-decoration: none;
    cursor: pointer;
}
.link:hover{
    cursor: pointer;
}
.goback_link{
    color: #69CD7C;
    text-decoration: none;
    cursor: pointer;
}
.list_item{
    font-size: 18px;
    margin: 10px 0;
}
@media only screen and (max-width:1000px) {
    .list img{
        width: 55%;
    }
}