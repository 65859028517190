.container{
    max-width: 800px;
    margin: 0 auto;
    padding: 10px;
}
.method_content_wrapper{
    margin-top: 40px;
}
/* title */
.title_wrapper{
    max-width: 800px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
/* ** */
/* text */
.method_text_wrapper{
    max-width: 600px;
    margin: 0 auto;
    margin-bottom: 40px;
}
.method_text{
    text-align: left;
    line-height: 20px;
    font-size: 20px;
    color: #727272;
}
/* ** */
/* Switchers */
.switcher{
    border: 1px solid #727272;
    height: 31px;
    border-radius: 60px;
    width: 51px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.method_swithers_container{
    max-width: 600px;
    margin: 0 auto;
}
.switcher_wrapper{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}
.switcher_text{
    color: #727272;
    margin-left: 10px;
}
/* *** */
/* button */
.button_container{
    max-width: 470px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    flex-direction: column;
    margin-bottom: 30px;
}
.button_back{
    width: 300px;
    height: 50px;
    background-color: #69CD7C;
    margin-top: 20px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    text-decoration: none;
}
.button{
    width: 300px;
    width: 300px;
    height: 50px;
    background-color: #69CD7C;
    border: none;
    color: #fff;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    text-decoration: none;
}
/* ** */