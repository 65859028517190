.container{
    max-width: 820px;
    margin: 0 auto;
}
.title_wrapper{
    max-width: 820px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    margin-bottom: 57px;
    align-items: center;
}
.title{
    color: #727272;
    max-width: 600px;
    margin: 0;
}
.manual_snils_wrapper{
    width: 500px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.manual_snils_wrapper img{
    width: 40%;
}

.manual_snils_text{
    text-align: left;
    color: #727272;
    font-size: 17px;
    margin-top: 35px;
    margin-bottom: 35px;
    font-weight: bold;
}
.input_wrapper{
    margin-top: 23px;
    position: relative;
    margin: 0 auto;
}
.error_text{
    color: red;
}
.input:focus{
   
    border:2px solid #69CD7C;

}
.input{
    outline: none;
        width: 100%;
        margin: 0;
        border: 2px solid #EAEEF7;
        border-radius: 5px;
        position: relative;
        max-width: 500px;
        height: 45px;
        padding-left: 20px;
        font-size: 16px;
        line-height: 6ex;
        transition:  .1s linear border;
        -webkit-animation: .1s linear border;
}
label {
    
    position: absolute;
    top: -1ex;
    z-index: 1;
    left: .5em;
    background-color: white;
    padding: 0 5px;
    color: #727272;
  }

.button_wrapper{
    display: flex;
    justify-content: center;
    margin-bottom: 113px;
}
.button{
    width: 300px;
    height: 50px;
    background-color: #69CD7C;
    border: none;
    color: #fff;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.upload_img{
    max-width: 73px;
   margin: auto 0;
}
.inputs_container{
    display: flex;
    justify-content: space-around;
    margin-top: 65px;
    margin-bottom: 55px;
}
.input_frame{
    position: relative;
    width: 500px;
    margin: 0 auto; 
    height: 239px;
    border: 1px solid #727272;
    border-radius: 5px;
    padding: 20px;
    display: flex;
    margin-bottom: 32px;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
}
.input_file{
    position: absolute;
    top: 0%;
    width: 500px;
    height: 239px;   
    opacity:0;
    display: block;
}
.text_place{
    margin-top: 20px;
    width: 100%;
}
.snils_text_wrapper{
    max-width: 500px;
    margin: 0 auto;
    margin-top: 50px;
    margin-bottom: 40px;
}
.faq_link{
    text-decoration: none;
    color: #69CD7C;
    cursor: pointer;
}
.img{
    border-radius: 10px;
}
@media screen and (max-width:667px) {
    .container{
        padding: 0 10px;
        margin-top: 20px;
}
    .title_wrapper{
        display: flex;
        flex-direction: column;
    }
   
    .input_text{
        margin-top:10px;
        margin-bottom: 15px;
        text-align: center;
    }
    .title{
        max-width: 300px;
        margin-top: 20px;
        height: 50px;
    }
    .input_frame{
        width: 300px;
    }
    .input_file{
        width: 300px;
    }
    .manual_snils_wrapper{
        width: auto;
    }

    .snils_text_wrapper{
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .inn_input_wrapper{
        width: 100%;
        margin: 0;
    }
    .snils_input_wrapper{
        width: 100%;
        margin: 0;
    }
    .input_wrapper{
        margin: 0 auto;
    }
    .title_wrapper{
        margin-bottom: 20px;
    }
}