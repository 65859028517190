.container{
    max-width: 800px;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 20px;
}
.confirmation_content{
    max-width: 600px;
    margin: 0 auto;
    margin-top: 30px;
}
/* title */
.title{
    height: inherit;
    margin-left: 20px;
}
.title_wrapper{
    max-width: 700px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
/* ** */
/* Text */
.confirmation_text{
    text-align: left;
    font-size: 20px;
    color: #727272;
    margin-bottom: 30px;
}
.confirmation_text_small{
    font-size: 16px;
    color: #727272;
    text-align: left;
    margin-bottom: 30px;
}

/* ** */
/* Button */
.button_wrapper{
    display: flex;
    justify-content: center;
    margin: 20px 0;
}
.button{
    width: 400px;
    border: none;
    background-color: #69CD7C;
    height: 50px;
    border-radius: 5px;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    
}
/* ** */
/* Link */
.link_wrapper{
    margin: 0 auto;
    max-width: 230px;
    margin-top: 20px;
}
.goback_link{
    text-decoration: none;
    color: #69CD7C;
    cursor: pointer;
}
/* ** */
/* Adaptivity */
@media screen and (max-width:425px) {
    .container{
        padding: 10px 10px;
    }
    .qr_text_wrapper{
        display: flex;
        flex-direction: column;
    }
    .buttons_container{
        flex-direction: column;
    }
    .button{
        margin-bottom: 20px;
    }
    .title_img{
        max-width: 80px;
    }
    .store_wrapper{
        flex-direction: column;
        align-items: center;
    }
    .apple_img,.google_img{
        max-width: 200px;
        margin-top: 0;
    }
}
@media screen and (min-width:425px) and (max-width:645px){
    .container{
        padding: 10px 10px;
    }
    .buttons_container{
        flex-direction: column;
    }
    .button{
        margin-bottom: 20px;
    }
}
@media screen and (min-width:579px) and (max-width:770px){
    .title{
        text-align: center;
        margin-left: 15px;
    }
    .title_wrapper{
        max-width: 579px;
        margin: 0 auto;
    }
}