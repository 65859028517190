.lock{
    position: fixed;
    left: 1vw;
    bottom: 3vh;
    cursor: pointer;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.097);
    border-radius: 50%;
    padding: 3px;
    z-index: 999;
}
.text{
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.08);
z-index: 999;
    padding: 10px;
    width: 150px;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.099);
    text-align: center;
    border-radius: 8px;
    position: fixed;
    left: 4vw;
    bottom: 3vh;
    color: rgba(0, 0, 0, 0.656);
    opacity: 0;
    display: none;
    -webkit-transition: .3 linear all;
    transition: .3s linear all;
}
.lock:hover~.text{
    opacity: 1;
    display: block;
}

