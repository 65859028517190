.content{
    max-width: 600px;
    margin: 0 auto;
    margin-top: 20px;
}
.link_wrapper{
    margin: 230px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
}
.list{
    color: #727272;
}
.link{
    color: #69CD7C;
    text-decoration: none;
    cursor: pointer;
    margin-left: 40px;
}
.goback_link{
    color: #69CD7C;
    text-decoration: none;
    cursor: pointer;
}
.list_item{
    font-size: 18px;
    margin: 10px 0;
}