.container {
    max-width: 800px;
    margin: 0 auto;
}

.title_wrapper {
    max-width: 800px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;
}

.button_wrapper {
    display: flex;
    justify-content: center;
}

.button {
    width: 470px;
    height: 50px;
    background-color: #69CD7C;
    border: none;
    color: #fff;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 20px;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

h1 {
    color: #727272;
    width: 650px;
    margin: 0;
}

.switch_container {
    display: flex;

    align-items: center;
    margin-bottom: 66px;
}

.switch_label {
    margin-left: 25px;
    font-size: 16px;
    color: #727272;
}

.switcher_frame {
    border: 1px solid #727272;
    height: 31px;
    border-radius: 60px;
    width: 51px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.upload_img {
    max-width: 73px;
    margin: auto 0;
}

.inputs_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 65px;
    margin-bottom: 55px;
}

.input_frame {
    position: relative;
    width: 400px;
    height: 400px;
    border: 1px solid #727272;
    border-radius: 5px;
    padding: 20px;
    display: flex;
    background-position: center;
    background-size: cover;
    align-items: center;
    flex-direction: column;
}

label {
    position: absolute;
    top: -1.3ex;
    z-index: 1;
    left: .5em;
    background-color: white;
    padding: 0 5px;
    color: #727272;
}

p {
    text-align: center;
    margin: 5px 0;
}

.text_wrapper {
    height: 120px;
    margin-bottom: 20px;
}
.img{
    border-radius: 10px;
}
.input {
    position: absolute;
    top: 0%;
    width: 300px;
    height: 300px;
    opacity: 0;
}

.button_forward,
.button_handwrite {
    width: 300px;
    height: 50px;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    color: #fff;
}

.button_forward {
    background-color: #69CD7C;
    color: #fff;
    border: none;
    width: 300px;
    margin-bottom: 0;
    height: 50px;
}

.button {
    width: 300px;
    margin: 0;
    height: 50px;
}

.button_handwrite {
    background-color: transparent;
    width: 300px;
    color: #fff;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
}

.buttons_wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    margin-bottom: 30px;
    width: 300px;

}

@media screen and (max-width:320px) {
    .container {
        padding: 0 30px;
        margin-top: 20px;
    }

   

    h1 {
        font-size: 16px;
        text-transform: uppercase;
        text-align: center;
        width: 200px;
        height: 100px;
    }

    .title_wrapper {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    .button_forward {
        width: 300px;
    }

    .button_handwrite {
        width: 300px;
    }

    .inputs_container {
        display: flex;
        flex-direction: column;
        margin-top: 0;
    }

    .input_frame {
        margin-top: 20px;
        width: 300px;
    }

   
}

@media screen and (min-width:321px) and (max-width:750px) {
    .container {
        padding: 0 30px;
        margin-top: 20px;
    }

    .img {
        width: 80px;
    }

    h1 {
        font-size: 16px;
        text-transform: uppercase;
        text-align: center;
        width: 200px;
        height: 100px;
        display: flex;
        align-items: center;
    }

    .title_wrapper {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    .button_forward {
        width: 300px;
    }

    .button_handwrite {
        width: 300px;
    }

    .inputs_container {
        display: flex;
        flex-direction: column;
        margin-top: 0;
    }

    .input_frame {
        margin-top: 20px;
        margin: 0 auto;
        max-width: 350px;
        max-height: 350px;        
        margin-top: 20px;
    }

    
}