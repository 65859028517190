.link{
    color: #69CD7C;
    text-decoration: none;
    cursor: pointer;
}
.link:hover{
    cursor: pointer;
}
.link_wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
}