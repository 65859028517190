.container{
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    width: 90vw;
    
}
.title{
    margin-top: 30px;
    text-align: center;
    font-size: 30px;
    color: #464646;
    width: 100%;
}
.subtitle{
    margin-top: 150px;
}
.greenText{
    color: #69cd7c;
}
.list li{
    margin-top: 10px;
}
.logo{
    margin-top: 50px;
}
.fileBlock{
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 15px 25px;
    border: 1px solid #4646462c;
    border-radius: 18px;
}
.pdfIcon{
    width: 24px;
}
.downloadIcon{
    width: 20px;
}