.container {
    background-color: #fff;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.08);
    height: 95vh;
    border: 1px solid #C5C5C5;
    border-radius: 4px;
    width: 85vw;
    overflow-y: scroll;
    overflow-x: scroll;
}

.modal {
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
    height: 600px;
    border: 2px solid #000;
    box-shadow: 24px;
    padding: 4;
}

.nav {
    height: 50px;
    align-items: center;
    display: flex;
    justify-content: space-around;

}

.show_btn {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    width: 100px;
    padding: 8px 16px;
    border-radius: 4px;
    color: #fff;
    background: #255F0B;
    transition: .1s linear all;
}

.show_btn:hover {
    background-color: #3f9a14;
}

.download_btn {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    width: 100px;
    padding: 8px 16px;
    border-radius: 4px;
    color: #fff;
    background: orange;
}

.download_btn:hover {
    background: rgb(255, 191, 0);

}

.show_btn img {
    width: 20px;
    height: 20px;
}

.download_btn img {
    width: 20px;
    height: 20px;
}