.container{
    max-width: 800px;
    margin: 0 auto;
    padding: 10px;
}
.title_wrapper{
    max-width: 800px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;
    align-items: center;
}
.title{
    text-transform: uppercase;
}
h1{
    color: #727272;
    width: 650px;
    margin: 0;
    height: 120px;
}

.switch_container{
    display: flex;
   
    align-items: center;
    margin-bottom: 66px;
}
.switch_label{
    margin-left: 25px;
    font-size: 16px;
    color: #727272;
}
.switcher_frame{
    border: 1px solid #727272;
    height: 31px;
    border-radius: 60px;
    width: 51px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.upload_img{
    max-width: 73px;
   margin: auto 0;
}
.inputs_container{
    display: flex;
    justify-content: space-around;
    margin-top: 65px;
    margin-bottom: 55px;
}
.input_frame{
    position: relative;
    width: 300px;
    height: 310px;
    border: 1px solid #727272;
    border-radius: 5px;
    padding: 20px;
    display: flex;
   
    align-items: center;
    flex-direction: column;
}
label {
    position: absolute;
    top: -1.3ex;
    z-index: 1;
    left: .5em;
    background-color: white;
    padding: 0 5px;
    color: #727272;
  }
p{
    text-align: center;
    margin: 5px 0;
}
.text_wrapper{
    height: 120px;
    margin-bottom: 20px;
}
.input{
    position: absolute;
    top: 0%;
    width: 300px;
    height: 300px;   
    opacity: 0;
}

.button_forward,.button_handwrite{
    width: 300px;
    height: 50px; 
    margin-bottom: 20px;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
}
.link{
    text-decoration: none;
}
.button_forward{
    background-color: #69CD7C;
    color: #fff;
    border: none;
    width: 300px;
}
.button{
    width: 300px;
}
.button_handwrite{
    background-color: transparent;
    width: 300px;
    border: 1px solid #69CD7C;
    color: #69CD7C;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
}
.buttons_wrapper{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    margin-bottom: 30px;
    width: 300px;
}

@media screen and (max-width:425px) {
    .contract_img{
        max-width: 80px;
    }
    .title{
        max-width: 300px;
    }
    .title_wrapper{
        justify-content: space-around;
    }
}

@media screen and (max-width:768px) and (min-width:426px){
    .title{
        max-width: 400px;
    }
    .container{
        padding: 10px;
    }
}