.wrapper{
    padding: 10px;
    max-width: 800px;
    height: 60vh;
    margin: 0 auto;
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
   
}
.container{
    height: 100vh;
  
}
.title{
    text-align: center;
    width: 100%;
}
.button_wrapper{
    width: 300px;
    margin: 0 auto;
    height: 50px;
}
.button{
    width: 300px;
    margin: 0 auto;
    height: 50px;
    background-color: #69CD7C;
    border: none;
    color: #fff;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    
}
.steps_block{
    display: flex;
    flex-direction: column;
    gap:10px;
}
.step{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.step span{
    font-size: 20px;
    margin-bottom: 10px;
   text-align: center;
}
.step img{
    border-radius: 24px;
    width: 40%;
}

@media screen and (max-width:425px) {
    .button{     
        margin: 0;
        margin-top: 30px;
    }
    .button_wrapper{
        margin: 0;
    }
    .wrapper{
        height: 80vh;
        margin-top: 20px;
        display: flex;
       flex-direction: column;
       justify-content: space-between;
    }
}