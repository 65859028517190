.container{
    max-width: 800px;
    margin: 0 auto;
    margin-bottom: 40px;
}
textarea::-webkit-scrollbar{
    display: none;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
.title_container{
    display: flex;
    justify-content: space-between;
}
.button{
    width: 300px;
}
/* SELECT */
.custom_select_wrapper{
    border: 1px solid #EAEEF7;
    background-color: #fff;
    border-radius: 10px;
    width: 100%;
    position: absolute;
    top: 100%;
    z-index: 10;
    box-shadow:  1px 4px 7px 5px #0000000a;
    display: block;
}
.custom_select_wrapper_disabled{
    display: none;
}
.select_list{
    padding: 2px;
    margin: 0;
}
.list_item{
    text-decoration: none;
    list-style: none;
    margin: 2px;
    padding: 3px 3px 3px 10px;
    cursor: pointer;
    text-align: left;
}
.list_item:hover{
    background-color: #eaeef782;
}
/* **SELECT** */
.title{
    height: 70px;
    max-width: 650px;
    text-transform: uppercase;
    margin-bottom: 15px;
}
.sub_title{
    width: 650px;
    text-align: left;
    text-transform: uppercase;
}

/* form*/
.form_wrapper{
    margin-top: 65px;
    margin-bottom: 40px;
}
.form{
   max-width: 490px;
    border: 1px solid #EAEEF7;
    border-radius: 15px;
    margin: 0 auto;
    margin-top: 15px;
}

.form_item{
    display: flex;
    width: 100%;
}
.form_item_adress{
    display: flex;
    width: 100%;
    height: 60px;
}
.form_name{
    width: 280px;
    padding: 5px 0 5px 15px;
    border-bottom: 1px solid #EAEEF7;
    border-right: 1px solid #EAEEF7;
}
.form_name_last{
    width: 280px;
    padding: 5px 0 5px 15px;
    border-right: 1px solid #EAEEF7;
}
.form_value_last{
    width: 220px;
    padding: 5px 0 5px 15px;
}
.form_value{
    width: 220px;
    padding: 5px 0 5px 15px;
    border-bottom: 1px solid #EAEEF7;
}
/* buttons */
.buttons{
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    max-width: 470px;
    margin: 0 auto;
}
.button_wrapper{
    display: flex;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 113px;
}
.button{
    width: 470px;
    height: 50px;
    background-color: #69CD7C;
    border: none;
    color: #fff;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
}
.button_small{
    width: 210px;
    height: 50px;
    border: 1px solid #69CD7C;
    background-color: transparent;
    color: #69CD7C;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
}
/* edit data */
.inputs_wrapper{
    max-width: 300px;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
}
.inputs_container{
    display: flex;
    max-width: 630px;
    margin: 0 auto;
    justify-content: space-between;
}
.input_wrapper{
    margin-top: 30px;
    position: relative;
}
.label{
    font-size: 14px;
    color: #727272;
}
.error_text{
    color: red;
    margin: 0 auto;
    position: absolute;
    top: 100%;
    left: 23%;
}
.input:focus{
    border:2px solid #69CD7C;

}
.input{
    width: 300px;
    height: 50px;
    padding: 1px 5px 1px 5px;
    font-size: 16px;
    border-radius: 5px;
    border:2px solid #EAEEF7;
    outline: none;
    position: relative;
    transition:  .1s linear border;
    -webkit-animation: .1s linear border;
}
.input_text:focus{
    border:2px solid #69CD7C;

}
.input_text{
    width: 300px;
    height: 130px;
    padding: 10px 10px;
    font-size: 16px;
    border-radius: 5px;
    border: 2px solid #EAEEF7;
    outline: none;
    position: relative;
    overflow-y: scroll;
    transition:  .1s linear border;
    -webkit-animation: .1s linear border;
}
.input_gender:focus{
    border:2px solid #69CD7C;

}
.input_gender{
    width: 300px;
    height: 50px;
    padding: 15px 5px;
    font-size: 16px;
    border-radius: 5px;
    border: 2px solid #EAEEF7;
    outline: none;
    position: relative;
    transition:  .1s linear border;
    -webkit-animation: .1s linear border;
}
.links_wrapper{
    display: flex;
    max-width: 630px;
    margin: 0 auto;
    justify-content: space-between;
}
.link{
    background-color: #69CD7C;
    border: none;
    color: #fff;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 500;
    width: 300px;
    cursor: pointer;
    text-decoration: none;
}
.link,.link_download{
    width: 300px;
    margin: 0 auto;
    height: 50px;
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.link_download{
    border: 1px solid #69CD7C;
    background-color: transparent;
    color: #69CD7C;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    text-decoration: none;
}
/* ******** */
@media screen and (max-width:667px){
    .links_wrapper{
        display: flex;
       flex-direction: column;
    }
    .title_container{
        flex-direction: column;
    }
    .inputs_container{
        display: flex;
        flex-direction: column;
    }
    .img{
        max-width: 100px;
        margin: 0 auto;
    }
    .title{
        max-width: 300px;
        max-height: 50px;
        margin-top: 20px;
        margin: 0 auto;
    }
    .sub_title{
        max-width: 300px;
        text-align: center;
        font-size: 14px;
        margin: 0 auto;
    }
    
    .container{
        padding: 0 10px;
        margin-top: 20px;
    }
    .form_name,.form_name_last{
        padding: 2px 5px 2px 5px;
        display: flex;
        align-items: center;
    }
    .form_wrapper{
        margin-top: 20px;
    }
   
}
@media screen and (min-width:667px) and (max-width:881px){
    .container{
        padding: 0 10px;
        margin-top: 20px;
    }
    .title{
        max-width: 500px;
        font-size: 25px;
        margin-bottom: 0;
        margin-left: 10px;
        text-align: left;
    }
    .title_container{
        display: flex;
        justify-content: center;
    }
    .sub_title{
        max-width: 500px;
        margin-left: 10px;
    }
}

