.navbar{
    max-width: 920px;
    height: 50px;
    border-radius: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    margin-top: 100px;
    margin-bottom: 58px;
}
.navbar_item_active{
    background-color: #69CD7C;
}
.item_wrapper_active{
    position: relative;
    height: 50px;
    width: 239px;
    transition: .3s linear;
}
.item_wrapper_off{
    position: relative;
    height: 50px;
    width: 50px;
    transition: .3s linear;

}
.navbar_item{
    background-color: #C1D3DC;
}
.navbar_item_adaptive{
    background-color: #C1D3DC;

}
.navbar_item,.navbar_item_active,.navbar_item_adaptive{
  
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 30px;
}
/* active section */

.navbar_active_section{
    width: 239px;
    border: 1px solid #69CD7C;
    padding: 0 0 0 40px;
    border-radius: 27px;
    height: 50px;
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    transition: .3s linear;
    display: flex;
    justify-content: center;
    left: 0;
}
.navbar_active_section_off{
    width: 0;
    height: 0;
    transition: .3s linear;
}
.active_section_text_off{
    display: none;
}
.active_section_text{
    color: #69CD7C;
    text-align: center;
    width: 185px;
    font-size: 14px;
   
}
/* dots */
.navbar_dots,.navbar_dots_adaptive{
    display: flex;
    width: 32px;
    justify-content: space-between;
}
.navbar_dots_item{
    background-color: #C1D3DC;
    border-radius: 50%;
    width: 8px;
    height: 8px;
}

/* Adaptive */
@media screen and (max-width:320px){
    .navbar_dots_adaptive{
        display: none; 
    }  
    .navbar_item_adaptive{
        display: none;
    }
    .navbar_item,.navbar_item_adaptive{
        max-width: 40px;
        max-height: 40px;
    }
    .navbar_dots{
        margin: 0 5px;
    }
    .navbar{
        justify-content: center;
        max-width: 300px;
        margin: 0 auto;
        margin-top: 30px;
        margin-bottom: 30px;
    }
    .navbar_active_section{
        max-width: 220px;
        padding: 5px 0px 5px 50px;
    }
}
@media screen and (max-width:425px) and (min-width:321px) {
    .navbar_dots_adaptive{
        display: none; 
    }  
    .navbar_item_adaptive{
        display: none;
    }
    .navbar_dots{
        margin: 0 5px;
    }
    .navbar{
        justify-content: center;
        max-width: 400px;
        margin: 0 auto;
        margin-top: 30px;
        margin-bottom: 30px;
    }
}
@media screen and (max-width:768px) and (min-width:426px){
    .navbar_item,.navbar_item_adaptive{
        max-width: 40px;
        max-height: 40px;
    }
    .navbar{
        padding: 0 10px;
    }
}