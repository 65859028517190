.container{
    max-width: 800px;
    margin: 0 auto;
}
/* title */
.title{
    height: inherit;
    margin-left: 20px;
    text-transform: uppercase;
}
.title_wrapper{
    max-width: 700px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
}
/* ** */
.button_container{
    max-width: 700px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 30px;
}
.buttons_container{
    max-width: 700px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 0 auto;
    margin-top: 10px;
    margin-bottom: 30px;
    flex-direction: column;
}
.button{
    max-width: 470px;
    min-width: 300px;
    height: 50px;
    background-color: #69CD7C;
    border: none;
    color: #fff;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    margin-top: 20px;
    text-decoration: none;
}
/* *** */
/* Text */
.text{
    text-align: center;
    color: #727272;
    font-size: 18px;
    margin-bottom: 0;
}
.text_main{
    text-align: left;
    color: #727272;
    font-size: 18px;
    max-width: 600px;
    margin: 0 auto;
    margin-bottom: 20px;
}
/* ** */
/* Img */
.store_wrapper{
display: flex;
justify-content: space-between;
max-width: 370px;
margin: 0 auto;
}
.google_img{
    margin: 24px 0;
}
.qr_wrapper{
    max-width: 300px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
}
/* ** */
/* Adaptivity */
@media screen and (max-width:425px) {
    .container{
        padding: 10px 10px;
    }
    .qr_text_wrapper{
        display: flex;
        flex-direction: column;
    }
    .buttons_container{
        flex-direction: column;
    }
    .button{
        margin-bottom: 20px;
    }
    .title_img{
        max-width: 80px;
    }
    .store_wrapper{
        flex-direction: column;
        align-items: center;
    }
    .apple_img,.google_img{
        max-width: 200px;
        margin-top: 0;
    }
}
@media screen and (min-width:425px) and (max-width:645px){
    .container{
        padding: 10px 10px;
    }
    .buttons_container{
        flex-direction: column;
    }
    .button{
        margin-bottom: 20px;
    }
}
@media screen and (min-width:579px) and (max-width:770px){
    .title{
        text-align: center;
        margin-left: 15px;
    }
    .title_wrapper{
        max-width: 579px;
        margin: 0 auto;
    }
}