.container{
    background-color: #fff;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.08);
    height: 95vh;
    border: 1px solid #C5C5C5;
    border-radius: 4px;
    width: 85vw;
    overflow-y: scroll;
    overflow-x:scroll;
}
.input {
    padding: 6px 12px;
    width: 256px;
    height: 32px;
    border: 1px solid #E0E0E0;
    border-radius: 4px;
    outline: none;
}
.input:focus{
    border: 1px solid #3f9a14;
    outline: 2px solid #3e9a1462;
}
.button{
    background: #255F0B;
    padding: 8px 16px;
    min-width: 83px;
    max-height: 35px;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}
.border{
    border-radius: 4px;
    border: 1px solid #C5C5C5;
box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.08);
}
.badge{
    border-radius: 20px;
    padding: 8px 8px;
    color: #fff;
}