.container{
    max-width: 1000px;
    margin: 0 auto;
    padding: 30px;  
    margin-top: 40px;
    background-color: #fff;
    border-radius: 4px;
    border-radius: 4px;
    border: 1px solid #C5C5C5;
box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.08);
}
.title{
    margin: 0 auto;
    text-align: center;
    width: 800px; 
}
.button_wrapper{
    display: flex;
    justify-content: center;
    margin-top: 30px;
}
.button{
    width: 300px;
    height: 50px; 
    margin-bottom: 20px;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    border: none;
    color: #fff;
    background-color: #69CD7C;
}
.signs_wrapper{
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    margin-top: 20px;
    gap: 20px;
}
.sign_frame{
    border-radius: 15px;
    border: 1px solid #C1D3DC;
    background-color: #fff;    
    width: 300px;
    height: 300px;
    cursor: pointer;
    padding: 10px;
    transition: .1s linear;
    box-shadow: 1px 2px 1px #C1D3DC ;
}
.sign_frame:hover{
    box-shadow: 5px 10px 5px #C1D3DC ;
}
.question_wrapper{
    display: flex;

    justify-content: flex-end;
}
.question_mark{
    border-radius: 50%;
    border: 1px solid #C1D3DC;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #C1D3DC;
    
}
.img_wrapper{
    display: flex;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 10px;
}
.sign_name_text{
    max-width: 300px;
    margin: 0 auto;
    margin-top: 30px;
    color: #727272;
}
@media screen and (max-width:425px){
    .container{
        padding: 0 10px;
        margin-top: 20px;
    }
    .title{
        width: 300px;
        height: auto;
        margin-bottom: 20px;
    }
    .signs_wrapper{
        display: flex;
        flex-direction: column;
    }
    .sign_frame{
        margin: 0 auto;
        margin-bottom: 20px;
    }
}
@media screen and (min-width:425px) and (max-width:984px){
    .signs_wrapper{
        display: flex;
        flex-direction: column;
    }
    .title{
        text-align: center;
        min-width: 100px;
        max-width: 500px;
    }
    .sign_frame{
        margin: 0 auto;
        margin-bottom: 20px;
    }
}
