.container{
        max-width: 725px;
        margin: 0 auto;
}
.fns_link{
    text-decoration: none;
    color: #69CD7C;
}
.error_text{
    color: red;
}
.inn_input_wrapper{
   width: 100%;
    margin: 0;
    position: relative;
    margin-right: 31px;
}
.img{
    width: 130px;
    border-radius: 10px;
}
.input{
    width: 100%;
}
.text_field{
    width: 500px;
    margin-top: 10px;
}
.inn_text{
    align-items: center;
    text-align: center;
    margin: 0 auto;
    max-width: 470px;
    text-align: left;
    font-size: 20px;
    color: #727272;
    margin-bottom: 30px;
}
.title_wrapper{
    max-width: 650px;
    margin: 0 auto;
    display: flex;
    margin-bottom: 57px;
    align-items: center;
}
.inn_input_wrapper{
    margin-left: 15px;
}
.input_text{
    text-align: left;
    font-weight: bold;
    font-size: 20px;
    color: #727272;
    margin: 0;
}
.input_wrapper{
    margin-top: 35px;
    position: relative;
    margin-right: 31px;
}
.input:focus{
    border:2px solid #69CD7C;
}
.input{
    outline: none;
        border: 2px solid #EAEEF7;
        border-radius: 5px;
        position: relative;
       width: 100%;
        height: 45px;
        margin: 0;
        padding-left: 20px;
        font-size: 16px;
        line-height: 6ex;
        transition:  .1s linear border;
        -webkit-animation: .1s linear border;
}
label {
    position: absolute;
    top: -1ex;
    z-index: 1;
    left: .5em;
    background-color: white;
    padding: 0 5px;
    color: #727272;
  }
  .button_wrapper{
    display: flex;
    justify-content: center;
    margin-bottom: 113px;
}
.button{
    width: 300px;
    height: 50px;
    background-color: #69CD7C;
    border: none;
    color: #fff;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
}
@media screen and (max-width:667px) {
    .container{
        padding: 0 10px;
        margin-top: 20px;
}
    .title_wrapper{
        display: flex;
        flex-direction: column;
    }
    .text_place{
        width: 100%;
    }
    .input_wrapper{
        margin: 0 auto;
        max-width: 500px;
    }
    .inn_input_wrapper{
        max-width: 500px;
        margin: 0;
    }
    .input{
        width: 100%;
    }
    .input_text{
        margin-top:10px;
        margin-bottom: 15px;
        text-align: center;
    }
    .title_wrapper{
        margin-bottom: 20px;
    }
}