.container {
    background: #D3DFCE;
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
}
.navbar{
    position: fixed;
}
